
import './CookieConsentBanner.css';

export default function CookieConsentBanner({ onConsent }) {
  return (
    <div className="cookie-consent-banner">
      <div className="cookie-consent-content">
        <p>We use cookies to improve your experience. Do you accept cookies?</p>
        <div className="cookie-consent-buttons">
          <button onClick={() => onConsent(true)}>Accept</button>
          <button onClick={() => onConsent(false)}>Decline</button>
        </div>
      </div>
    </div>
  );
}


