import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ProductCarousel.css';

const ProductCarousel = ({ products }) => {
  const sliderRef = useRef(null);

  useEffect(() => {
    if (sliderRef.current) {
      console.log('Resetting slider position to 0');
      sliderRef.current.slickGoTo(0); // Ensure the slider starts from the beginning on refresh
    }
  }, [products]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 200,
    slidesToShow: 5.5,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 1000,
    touchTheshold: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 4,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 2,
        },
      },
    ],
  };

  // Handle the case when products are not yet loaded
  if (!products || products.length === 0) {
    return <div className="slider">No products available</div>;
  }

  return (
    <div className="slider">
      <Slider ref={sliderRef} {...settings}>
        {products.map((product) => (
          <div key={product.id} className="slider-product-container">
            <a href={`/prices/${product.name.replace(/[^a-zA-Z\d\s:]/g, '').replace(/ /g, "-")}-${product.id}`}>
              <div className="slider-product">
                <img 
                  src={product.image_url}
                  alt={product.name} 
                  className="img1" 
                  onError={(e) => { e.target.src = "/assets/images/no-image.jpg";}}
                />
                <p className="title">{product.name}</p>
                <p className="price">£{product.bestPrice}</p>
                {product.wasPrice && <p className='was-price'>Was: £{product.wasPrice}</p>}
                {product.price_drop_percentage && <span>{Math.round(product.price_drop_percentage)}% cheaper today</span>}
              </div>
            </a>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ProductCarousel;
