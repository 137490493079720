import { useParams } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import SearchResultsComponent from '../components/SearchResultsComponent';
import PriceSlider from "../components/PriceSlider";
import AgeGroupSlider from "../components/AgeGroupSlider";
import PiecesSlider from "../components/PiecesSlider"; // Import the new PiecesSlider component
import { Helmet } from "react-helmet";
import DOMPurify from 'dompurify';
import './Search.css';

function Search() {
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filterCategory, setFilterCategory] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [priceRange, setPriceRange] = useState([0, 1000]);
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(1000);
    const [ageRange, setAgeRange] = useState([0, 18]);
    const [minAge, setMinAge] = useState(0);
    const [maxAge, setMaxAge] = useState(18);
    const [piecesRange, setPiecesRange] = useState([0, 1000]);
    const [minPieces, setMinPieces] = useState(0);
    const [maxPieces, setMaxPieces] = useState(1000);
    const siteName = `${process.env.REACT_APP_SITE_NAME}`;

    const params = useParams(); 
    let endpoint = ""
    if (params.text) {
        endpoint = `${process.env.REACT_APP_API_URL}/search/${params.text}`;
    }

    if (params.category) {
        endpoint = `${process.env.REACT_APP_API_URL}/browse/${params.category}`;
    }

    const handleCategoryFilter = useCallback((category, isChecked) => {
        setSelectedCategories(prevSelected => {
            if (isChecked) {
                return [...prevSelected, category];
            } else {
                return prevSelected.filter(cat => cat !== category);
            }
        });
    }, []);

    const handlePriceChange = (newRange) => {
        setPriceRange(newRange);
    };

    const handleAgeChange = (newRange) => {
        setAgeRange(newRange);
    };

    const handlePiecesChange = (newRange) => {
        setPiecesRange(newRange);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await fetch(endpoint);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                let data = await response.json();

                let categories = [];
                let prices = data.map(item => parseFloat(item.bestPrice));
                let ages = data.map(item => parseInt(item.agegroup.replace('+', ''))); // Extract numeric value from agegroup
                let pieces = data.map(item => isNaN(parseInt(item.pieces)) ? 0 : parseInt(item.pieces)); // Handle NaN pieces value

                if (prices.length > 0) {
                    setMinPrice(Math.min(...prices));
                    setMaxPrice(Math.max(...prices));
                    setPriceRange([Math.min(...prices), Math.max(...prices)]);
                }

                if (ages.length > 0) {
                    setMinAge(Math.min(...ages));
                    setMaxAge(Math.max(...ages));
                    setAgeRange([Math.min(...ages), Math.max(...ages)]);
                }

                if (pieces.length > 0) {
                    setMinPieces(Math.min(...pieces));
                    setMaxPieces(Math.max(...pieces));
                    setPiecesRange([Math.min(...pieces), Math.max(...pieces)]);
                }

                data.forEach(item => {
                    if (item.category && !categories.includes(item.category)) {
                        categories.push(item.category);
                    }
                });

                setSearchResults(data);
                setFilterCategory(categories);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [endpoint, params.text]);

    const applyFilters = useCallback(() => {
        return searchResults.filter(result => {
            const inCategory = selectedCategories.length === 0 || selectedCategories.includes(result.category);
            const inPriceRange = !isNaN(result.bestPrice) && result.bestPrice >= priceRange[0] && result.bestPrice <= priceRange[1];
            const resultAge = parseInt(result.agegroup.replace('+', '')); // Extract numeric value from agegroup
            const inAgeRange = resultAge >= ageRange[0] && resultAge <= ageRange[1];
            const inPiecesRange = !isNaN(result.pieces) && result.pieces >= piecesRange[0] && result.pieces <= piecesRange[1];
            return inCategory && inPriceRange && inAgeRange && inPiecesRange;
        });
    }, [searchResults, selectedCategories, priceRange, ageRange, piecesRange]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error.message}</p>;
    }

    if (searchResults.length === 0) {
        return <p>No data available</p>;
    }

    const filteredResults = applyFilters();
    // Determine meta tags based on params
    let title = `${siteName} - Default Title`;
    let description = 'Default description';
    let keywords = 'Default,Keywords';
    let info = "";
    const sanitizeAndValidateInput = (input) => {
        const regex = /^[a-zA-Z0-9\s]+$/; // Allow only alphanumeric characters and spaces
        if (!regex.test(input)) {
            return "Invalid search term";
        }
        return DOMPurify.sanitize(input); // Sanitize if valid
    };
    if (params.text) {


        const sanitizedText = sanitizeAndValidateInput(params.text);
        title = `${siteName} - Search for ${params.text}`;
        description = `Compare price of Lego Sets like ${params.text}`;
        keywords = `Compare,Price,Lego Set,Construction,Toy,${params.text}`;
        info = `Search Results for "${sanitizedText}"`;
        
    } else if (params.category) {

        const sanitizedText = sanitizeAndValidateInput(params.category);
        if (params.category === 'daily') {
            title = `${siteName} - Daily Deals`;
            description = `See all lego sets which are at 10% cheaper today than they were yesterday`;
            keywords = `Compare,Price,Lego Set,Construction,Toy,${params.category}`;
            info = "Daily Deals"
        }
        else if (params.category === 'weekly') {
            title = `${siteName} - Weekly Deals`;
            description = `See all lego sets which are at 10% cheaper today than they were 7 days ago`;
            keywords = `Compare,Price,Lego Set,Construction,Toy,${params.category}`;
            info = "Weekly Deals"
        }
        else {
            title = `${siteName} - Search for ${params.category}`;
            description = `Compare price of Lego Sets like ${params.category}`;
            keywords = `Compare,Price,Lego Set,Construction,Toy,${params.category}`;
            info = `Browse for "${sanitizedText}" Lego Sets on BrickPrices.co.uk. Use the filter options above or beside the Lego Sets Listed below to refine your search. Click on the BrickPrices logo to return to the home page`;
        }

        if (params.category === 'rare')
            info = `Welcome to the Hard to Find Lego Sets Page. This page features a selection of the current Lego Sets that Lego.com themselves deem hard to find. This list comes with no
        guarantees and you will find that some of the items listed are easier to find than others, make of it what you will. Above all Play Well and enjoy the search`;
        else if (params.category === 'popular') {
            info = `Welcome to the Popular Lego Sets Page. This page features a selection of the current Lego Sets which are trending on sites like Amazon and Lego.com. I make no promises that
            these lego set will be popular with whoever you might be buying for; who knows why these lego sets are popular right now. Maybe they are heavily discounted, maybe they have just been
            released, or maybe, just maybe they are the next big thing. Only you can decide. Remember, above all Play Well and enjoy the search`
        }
        else if (params.category === 'latest') {
            info = `Welcome to the New Lego Sets Page. Here you will find the newest Sets in the Lego Range. Use the filter bars either above or beside this list to aid you and Above all Play Well and enjoy the search`
        }
        else if (params.category === 'retiring') {
            info = `Welcome to the Retiring Lego Sets Page. Here you will find the all the sets in the Lego Range which have been identified as 'Retiring Soon'. We don't know any more than that, the exact dates of retirement are elusive, but if Lego.com say they are retiring then thats good enough for us. Use the filter bars either above or beside this list to aid you and Above all Play Well and enjoy the search`
        }
        else if (params.category === 'discounted') {
            info = `Welcome to the Deepest Discount Lego Sets Page. This is something of a prototype and it lists all the sets in our database where the price of the cheapest retailer is more than 50% less than
            than the price of the most expensive retailer. Use the filter bars either above or beside this list to aid you and Above all Play Well and enjoy the search`
        }
        else if (params.category === 'daily') {
            info = `Welcome to the Daily Discounts Page. Here, you'll find all the Lego sets with prices that are at least 10% lower today compared to yesterday. This page is something of a prototype. Use the filter bars above or beside this list to help refine your search. Prices update multiple times a day, so be sure to check back regularly for the latest deals. Above all, Play Well and enjoy the search!`
        }
        else if (params.category === 'weekly') {
            info = `Welcome to the Weekly Discounts Page. Here, you'll find all the Lego sets with prices that are at least 10% lower today compared to 7 days. This page is something of a prototype. Use the filter bars above or beside this list to help refine your search. Prices update multiple times a day, so be sure to check back regularly for the latest deals. Above all, Play Well and enjoy the search!`
        }
        else {
            info = info
        }
    }

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description}></meta>
                <meta name="keywords" content={keywords}></meta>
            </Helmet>

            <div className="main-container">
                {info &&  (
                    <div className="info">
                        {info}
                    </div>
                )}

                <aside className="filter-sidebar">
                    <h2>Filter Results</h2>
                    <div className="filter-group">
                        {filterCategory.length > 1 && (
                            <>
                                <h3>Category</h3>
                                {filterCategory.map((cat, index) => (
                                    <label key={index}>
                                        <input 
                                            type="checkbox" 
                                            onChange={(e) => handleCategoryFilter(cat, e.target.checked)} 
                                        />
                                        {cat}<br/>
                                    </label>
                                ))}
                            </>
                        )}
                    </div>
                    <div className="filter-group">
                        <h3>Price</h3>
                        <PriceSlider 
                            priceRange={priceRange}
                            handlePriceChange={handlePriceChange}
                            minPrice={minPrice}
                            maxPrice={maxPrice}
                        />
                    </div>
                    {minAge !== maxAge && (
                        <div className="filter-group">
                            <h3>Age Group</h3>
                            <AgeGroupSlider
                                ageRange={ageRange}
                                handleAgeChange={handleAgeChange}
                                minAge={minAge}
                                maxAge={maxAge}
                            />
                        </div>
                    )}
                    {minPieces !== maxPieces && (
                        <div className="filter-group">
                            <h3>Pieces</h3>
                            <PiecesSlider
                                piecesRange={piecesRange}
                                handlePiecesChange={handlePiecesChange}
                                minPieces={minPieces}
                                maxPieces={maxPieces}
                            />
                        </div>
                    )}
                </aside>
                <div className="product-container">
                    {filteredResults.map((result, index) => {
                        // Convert bestPrice to a number and handle edge cases
                        let bestPrice = parseFloat(result.bestPrice);
                        if (isNaN(bestPrice)) {
                            console.error("bestPrice is not a number", result.bestPrice);
                            bestPrice = "0";
                        } else {
                            bestPrice = bestPrice.toFixed(2).toString();
                        }

                        return (
                            <SearchResultsComponent 
                                key={index} 
                                id={result.id} 
                                name={result.name} 
                                bestPrice={bestPrice} 
                                wasPrice={result.wasPrice}
                                price_drop_percentage={result.price_drop_percentage}
                                image_url={result.image_url}
                            />
                        );
                    })}
                </div>
            </div>
        </>
    );
}

export default Search;
