import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import PricesComponent from "../components/PricesComponent";
import { Helmet } from "react-helmet";
import { FaFacebookF, FaTwitter, FaPinterest } from "react-icons/fa";

import './Prices.css';

function Prices() {
    const [prices, setPrices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showFullDescription, setShowFullDescription] = useState(false);

    const params = useParams();
    let paramArray = params.id.split("-");
    const id = paramArray.pop();

    const [testName, setTestName] = useState('');
    const [name, setName] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        let paramArray = params.id.split("-");
        const id = paramArray.pop();
        const name = paramArray.join("-");
        setName(name);

        const checkName = async () => {
            try {
                let u = process.env.REACT_APP_API_URL + `/name/${id}`;
                let response = await fetch(u);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                let data = await response.json();
                return data[0].name;
            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
                return null;
            }
        };

        const fetchAndProcessName = async () => {
            let fetchedName = await checkName();
            if (fetchedName !== null) {
                let processedName = fetchedName.replace(/[^a-zA-Z\d\s:]/g, '').replace(/ /g, "-");
                setTestName(processedName);

                if (processedName !== name) {
                    navigate(`/prices/${processedName}-${id}`, { replace: true });
                }
            } else {
                console.log('Failed to fetch or process the name');
            }
        };

        fetchAndProcessName();
    }, [params.id, navigate]);

    let endpoint = process.env.REACT_APP_API_URL + `/prices/${id}`;
    const siteName = `${process.env.REACT_APP_SITE_NAME}`;

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await fetch(endpoint);
                if (!response.ok) {
                    throw new Error(`Network response was not ok: ${response.status} ${response.statusText}`);
                }

                let data = await response.json();
                console.log('Fetched data:', data); // Added log to check fetched data
                setPrices(data);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [endpoint]);

    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };

    const getPriceClassName = (price, bestPrice, yesterdayPrice, sevenDaysAgoPrice) => {
        // Create an array of prices
        const prices = [bestPrice, yesterdayPrice, sevenDaysAgoPrice];
    
        // Sort the prices in ascending order (cheapest to most expensive)
        const sortedPrices = [...prices].sort((a, b) => a - b);
    
        // Get the index of the current price in the sorted list
        const priceRank = sortedPrices.indexOf(price);
    
        // Assign class names based on the ranking
        if (priceRank === 0) return 'price-best'; // Cheapest (green)
        if (priceRank === 1) return 'price-mid';  // Mid-range (blue)
        return 'price-high'; // Most expensive (red)
    };
    

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error.message}</p>;
    }

    // Check if there are valid prices available
    const hasValidPrices = prices.some(price => price.price !== null && price.price !== undefined);

    return (
        <>
            <Helmet>
                <title>{siteName} - {prices[0]?.productName} - Price Comparison</title>
                <meta name="description" content={`Compare price of Lego Set ${prices[0]?.lego_ID} - ${prices[0]?.productName}`} />
                <meta name="keywords" content={`Compare,Price,Lego Set,Construction,Toy,${prices[0]?.lego_ID},${prices[0]?.productName}`} />
            </Helmet>

            <div className="breadcrumb">
                <a href="/">Home</a> &gt;
                {prices[0]?.category && (
                    <>
                        <a href={`/browse/${prices[0].category}`}>{prices[0].category}</a> &gt;
                    </>
                )}
                <span>{prices[0]?.productName}</span>
            </div>

            <div className="container">
                <div className="product-image">
                    <img
                        src={prices[0]?.image_url}
                        alt={prices[0]?.productName}
                        width="200"
                        height="200"
                    />
                </div>
                <div className="product-details">
                    <h1 className="product-title">{prices[0]?.productName}</h1>
                    <p className={`product-description ${showFullDescription ? 'full' : 'limited'}`}>
                        {prices[0]?.productDesc}
                    </p>
                    <button onClick={toggleDescription}>
                        {showFullDescription ? "Show less" : "Show more"}
                    </button>

                    {/* Render price section if there are valid prices */}
                    {hasValidPrices ? (
                        <div className="price-container">
                            <p className={`best-price ${getPriceClassName(prices[0]?.bestPrice, prices[0]?.bestPrice, prices[0]?.yesterdayPrice, prices[0]?.sevenDaysAgoPrice)}`}>
                                Best Price Today: £{prices[0]?.bestPrice}
                            </p>
                            <p className={`best-price ${getPriceClassName(prices[0]?.yesterdayPrice, prices[0]?.bestPrice, prices[0]?.yesterdayPrice, prices[0]?.sevenDaysAgoPrice)}`}>
                                Yesterday: £{prices[0]?.yesterdayPrice}
                            </p>
                            <p className={`best-price ${getPriceClassName(prices[0]?.sevenDaysAgoPrice, prices[0]?.bestPrice, prices[0]?.yesterdayPrice, prices[0]?.sevenDaysAgoPrice)}`}>
                                Last Week: £{prices[0]?.sevenDaysAgoPrice}
                            </p>

                        </div>
                    ) : (
                        <p>No prices available.</p>
                    )}

                    <div className="share-buttons">
                        <a
                            href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent(`${prices[0]?.productName} - Check out the latest prices!`)}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Share on Twitter"
                        >
                            <FaTwitter />
                        </a>

                        <a
                            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Share on Facebook"
                        >
                            <FaFacebookF />
                        </a>

                        <a
                            href={`https://pinterest.com/pin/create/button/?url=${encodeURIComponent(window.location.href)}&media=${encodeURIComponent(prices[0]?.image_url)}&description=${encodeURIComponent(`${prices[0]?.productName} - Check out the latest prices!`)}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Share on Pinterest"
                        >
                            <FaPinterest />
                        </a>
                    </div>
                </div>
            </div>

            {/* Conditionally render the price-comparison section */}
            {hasValidPrices && (
                <div className="container">
                    <div className="price-comparison">
                        <h2>Latest Prices</h2>
                        <div className="vendor-cards">
                            {prices.map((price, index) => (
                                <PricesComponent
                                    key={index}
                                    store={price.store}
                                    url={price.url}
                                    price={price.price !== null && price.price !== undefined ? price.price.toFixed(2).toString() : "N/A"}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Prices;
