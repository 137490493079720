// src/utils/loadGoogleAnalytics.js
export const loadGoogleAnalytics = (trackingId) => {
    // Check if the script is already added
    if (document.getElementById('google-analytics')) return;

    // Create script element for Google Analytics
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    script.id = 'google-analytics';

    // Append the script to the document head
    document.head.appendChild(script);

    // Initialize Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag() {
        window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', trackingId);
};
