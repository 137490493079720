// src/pages/Admin.js
import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';  // Corrected import statement
import './Admin.css';

const AdminPage = ({ token }) => {
  const [products, setProducts] = useState([]);
  const [editingProduct, setEditingProduct] = useState(null);
  const [showDescription, setShowDescription] = useState(true);
  const [showNoAsin, setShowNoAsin] = useState(false);
  const [showNoCategory, setShowNoCategory] = useState(false);
  const [showNoAgeGroup, setShowNoAgeGroup] = useState(false); // New state for age group filter
  const [legoIDFilter, setLegoIDFilter] = useState(''); // New state for lego_ID filter
  const navigate = useNavigate();

  const checkTokenExpiration = useCallback((token) => {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        // Token is expired
        navigate('/login');
      }
    } catch (error) {
      console.error('Invalid token:', error);
      navigate('/login');
    }
  }, [navigate]);

  const fetchProducts = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/products`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setProducts(data);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  }, [token]);

  useEffect(() => {
    const intervalId = setInterval(() => checkTokenExpiration(token), 60000); // Check token every 60 seconds

    checkTokenExpiration(token); // Initial check
    fetchProducts();

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [token, navigate, checkTokenExpiration, fetchProducts]);

  const saveProduct = async (product) => {
    try {
      checkTokenExpiration(token);
      console.log("Saving product with token:", token);
      const method = product.lego_ID ? 'POST' : 'POST';
      const url = product.lego_ID
        ? `${process.env.REACT_APP_API_URL}/products/${product.lego_ID}`
        : `${process.env.REACT_APP_API_URL}/products`;

      console.log(url)
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(product),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      fetchProducts();
      setEditingProduct(null);  // Exit editing mode after save
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  const deleteProduct = async (lego_ID) => {
    try {
      checkTokenExpiration(token);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/products/${lego_ID}`, {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      fetchProducts();
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  const handleInputChange = (e, field) => {
    setEditingProduct({
      ...editingProduct,
      [field]: e.target.value,
    });
  };

  const handleLegoIDFilterChange = (e) => {
    setLegoIDFilter(e.target.value);
  };

  const toggleDescription = () => {
    setShowDescription(!showDescription);
  };

  const toggleNoAsin = () => {
    setShowNoAsin(!showNoAsin);
  };

  const toggleNoCategory = () => {
    setShowNoCategory(!showNoCategory);
  };

  const toggleNoAgeGroup = () => {
    setShowNoAgeGroup(!showNoAgeGroup);
  };

  const filteredProducts = products.filter(product => {
    if (showNoAsin && !product.ASIN) {
      return true;
    }
    if (showNoCategory && !product.category) {
      return true;
    }
    if (showNoAgeGroup && !product.agegroup) { // Filter condition for age group
      return true;
    }
    if (legoIDFilter && product.lego_ID !== legoIDFilter) { // Filter condition for lego_ID
      return false;
    }
    if (!showNoAsin && !showNoCategory && !showNoAgeGroup) {
      return true;
    }
    return false;
  });

  const noAsinCount = products.filter((product) => !product.ASIN).length;
  const noCategoryCount = products.filter((product) => !product.category).length;
  const noAgeGroupCount = products.filter((product) => !product.agegroup).length; // Count for age group

  return (
    <div className="admin-container">
      <Sidebar
        toggleDescription={toggleDescription}
        showDescription={showDescription}
        toggleNoAsin={toggleNoAsin}
        toggleNoCategory={toggleNoCategory}
        toggleNoAgeGroup={toggleNoAgeGroup} // Pass toggle function to Sidebar
        showNoCategory={showNoCategory}
        showNoAsin={showNoAsin}
        showNoAgeGroup={showNoAgeGroup} // Pass state to Sidebar
        noAsinCount={noAsinCount}
        noCategoryCount={noCategoryCount}
        noAgeGroupCount={noAgeGroupCount} // Pass count to Sidebar
        legoIDFilter={legoIDFilter} // Pass lego_ID filter state to Sidebar
        handleLegoIDFilterChange={handleLegoIDFilterChange} // Pass lego_ID filter change handler to Sidebar
        totalCount={filteredProducts.length}
      />
      <div className="admin-content">
        <h1>Admin Page</h1>
        <table>
          <thead>
            <tr>
              <th>Lego ID</th>
              <th>ASIN</th>
              <th>Name</th>
              <th>Best Price</th>
              <th>RRP</th>
              {showDescription && <th>Description</th>}
              <th>Age</th>
              <th>Pieces</th>
              <th>Category</th>
              <th>Release Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredProducts.map((product) => (
              <tr key={product.lego_ID}>
                <td>
                  {editingProduct?.lego_ID === product.lego_ID ? (
                    <input
                      type="text"
                      value={editingProduct.lego_ID}
                      onChange={(e) => handleInputChange(e, 'lego_ID')}
                    />
                  ) : (
                    product.lego_ID
                  )}
                </td>
                <td>
                  {editingProduct?.lego_ID === product.lego_ID ? (
                    <input
                      type="text"
                      value={editingProduct.ASIN}
                      onChange={(e) => handleInputChange(e, 'ASIN')}
                    />
                  ) : (
                    product.ASIN
                  )}
                </td>
                <td>
                  {editingProduct?.lego_ID === product.lego_ID ? (
                    <input
                      type="text"
                      value={editingProduct.productName}
                      onChange={(e) => handleInputChange(e, 'productName')}
                    />
                  ) : (
                    product.productName
                  )}
                </td>
                <td>
                  {editingProduct?.lego_ID === product.lego_ID ? (
                    <input
                      type="text"
                      value={editingProduct.bestPrice}
                      onChange={(e) => handleInputChange(e, 'bestPrice')}
                    />
                  ) : (
                    product.bestPrice
                  )}
                </td>
                <td>
                  {editingProduct?.lego_ID === product.lego_ID ? (
                    <textarea
                      value={editingProduct.RRP}
                      onChange={(e) => handleInputChange(e, 'RRP')}
                    />
                  ) : (
                    product.RRP
                  )}
                </td>
                {showDescription && (
                  <td>
                    {editingProduct?.lego_ID === product.lego_ID ? (
                      <textarea
                        value={editingProduct.productDesc}
                        onChange={(e) => handleInputChange(e, 'productDesc')}
                      />
                    ) : (
                      product.productDesc
                    )}
                  </td>
                )}
                <td>
                  {editingProduct?.lego_ID === product.lego_ID ? (
                    <textarea
                      value={editingProduct.agegroup}
                      onChange={(e) => handleInputChange(e, 'agegroup')}
                    />
                  ) : (
                    product.agegroup
                  )}
                </td>
                <td>
                  {editingProduct?.lego_ID === product.lego_ID ? (
                    <textarea
                      value={editingProduct.pieces}
                      onChange={(e) => handleInputChange(e, 'pieces')}
                    />
                  ) : (
                    product.pieces
                  )}
                </td>
                <td>
                  {editingProduct?.lego_ID === product.lego_ID ? (
                    <textarea
                      value={editingProduct.category}
                      onChange={(e) => handleInputChange(e, 'category')}
                    />
                  ) : (
                    product.category
                  )}
                </td>
                <td>
                  {editingProduct?.lego_ID === product.lego_ID ? (
                    <textarea
                      value={editingProduct.releaseDate}
                      onChange={(e) => handleInputChange(e, 'releaseDate')}
                    />
                  ) : (
                    product.releaseDate
                  )}
                </td>
                <td>
                  {editingProduct?.lego_ID === product.lego_ID ? (
                    <>
                      <button className="save" onClick={() => saveProduct(editingProduct)}>Save</button>
                      <button className="cancel" onClick={() => setEditingProduct(null)}>Cancel</button>
                    </>
                  ) : (
                    <>
                      <button className="edit" onClick={() => setEditingProduct(product)}>Edit</button>
                      <button className="delete" onClick={() => deleteProduct(product.lego_ID)}>Delete</button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const Sidebar = ({
  toggleDescription,
  showDescription,
  toggleNoAsin,
  showNoAsin,
  noAsinCount,
  toggleNoCategory,
  showNoCategory,
  noCategoryCount,
  toggleNoAgeGroup, // New toggle function
  showNoAgeGroup, // New state
  noAgeGroupCount, // New count
  legoIDFilter, // New state for lego_ID filter
  handleLegoIDFilterChange, // New change handler for lego_ID filter
  totalCount,
}) => (
  <div className="sidebar">
    <h3>Filters</h3>
    <label>
      <input
        type="checkbox"
        checked={showDescription}
        onChange={toggleDescription}
      />
      <span></span>
      Show Description
    </label>
    <label>
      <input
        type="checkbox"
        checked={showNoAsin}
        onChange={toggleNoAsin}
      />
      <span></span>
      Show No ASIN ({noAsinCount})
    </label>
    <label>
      <input
        type="checkbox"
        checked={showNoCategory}
        onChange={toggleNoCategory}
      />
      <span></span>
      Show No Category ({noCategoryCount})
    </label>
    <label>
      <input
        type="checkbox"
        checked={showNoAgeGroup}
        onChange={toggleNoAgeGroup}
      />
      <span></span>
      Show No Age Group ({noAgeGroupCount})
    </label>
    <label>
      <span>Lego ID Filter</span>
      <input
        type="text"
        value={legoIDFilter}
        onChange={handleLegoIDFilterChange}
      />
    </label>
    <p>Total Products: {totalCount}</p>
  </div>
);

export default AdminPage;
