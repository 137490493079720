import  { useState } from 'react';
import './Contact.css'; // Optional: if you want to apply custom styles

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [formStatus, setStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let endpoint = process.env.REACT_APP_API_URL + `/contact`;
    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setStatus('Message sent successfully!');
        setFormData({ name: '', email: '', message: '' }); // Reset form
      } else {
        const errorData = await response.json();
        setStatus(`Error sending message`);
      }
    } catch (error) {
      setStatus(`Error sending message`);
    }
  };


  return (
    <div className="contact-container">
      <h1>Contact Me</h1>
      <p>If you have any questions, suggestions, or just want to say hello, feel free to reach out! I'd love to hear from you.</p>
      
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            rows="8"
            required
          />
        </div>

        <button type="submit">Send Message</button>

        {formStatus && <p className="form-status">{formStatus}</p>}
      </form>
    </div>
  );
};

export default Contact;

