// src/components/PrivateRoute.js
import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { jwtDecode }  from 'jwt-decode';

const PrivateRoute = ({ token, children }) => {
  if (!token) {
    return <Navigate to="/login" replace />;
  }

  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    if (decodedToken.exp < currentTime) {
      localStorage.removeItem('token');
      return <Navigate to="/login" replace />;
    }
  } catch (error) {
    console.error('Invalid token', error);
    localStorage.removeItem('token');
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default PrivateRoute;
