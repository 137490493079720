import { useEffect, useState } from 'react';
import './Footer.css';

export default function Footer({ onChangeConsent }) { // Destructure props to get onChangeConsent directly
    const [footerCategories, setFooterCategories] = useState(null);
    const [footerError, setFooterError] = useState("");
    const [footerLoading, setFooterLoading] = useState(true);

    const url = process.env.REACT_APP_API_URL + `/home/themes`;

    const fetchData = async (url, setFooterCategories, setFooterError, setFooterLoading) => {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Unable to fetch data');
            }
            const data = await response.json();
            setFooterCategories(data);
        } catch (error) {
            setFooterError(error);
        } finally {
            setFooterLoading(false);
        }
    };

    useEffect(() => {
        const getFooterCategories = async () => {
            await fetchData(url, setFooterCategories, setFooterError, setFooterLoading);
        };

        getFooterCategories();
    }, [url]);

    if (footerLoading) {
        return <p>Loading...</p>;
    }

    if (footerError) {
        return <p>Error: {footerError.message}</p>;
    }

    return (
        <div className="footer-container">
            <p>Check out these other great sets and themes below....</p>
            <ul>
                {footerCategories && footerCategories.map((category, index) => (
                    <li key={index}><a href={`/browse/${category.category}`}>{category.category}</a></li>
                ))}
            </ul>
            <button onClick={onChangeConsent} className="change-consent-button">
                Change Cookie Consent
            </button>
        </div>
    );
}
