function Prrices(props) {
    const i = '/assets/images/' + props.store + '.png'
    return (
        <>
            <div className = "vendor-card">
                <div className ="vendor-name"><img src={i}/></div>
                <div className ="vendor-price">£{props.price}</div>
                <a href={props.url} className="buy-button">Buy Now</a>
            </div>
        </>
    );
}
export default Prrices;