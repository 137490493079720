
import './About.css'; // Optional: if you want to apply custom styles

const About = () => (
  <div className="about-container">
    <h1>About</h1>
    <p>Hello and welcome to my Lego price comparison website! My name is Stephen. As a huge Lego fan, I’ve always been fascinated by the endless possibilities and creativity Lego sets provide. But over the years, I’ve noticed something that many Lego fans have also experienced—the rising cost of the hobby.</p>

    <p>This website is my side project, born out of a passion for Lego and a desire to help others like myself navigate the sometimes overwhelming cost of collecting. I built this site in my spare time with the hope that it will help people find better deals on Lego sets, whether for themselves or for their loved ones.</p>

    <p>Right now, the only revenue the site generates comes through Amazon affiliate links. However, I don’t exclude any other stores just because I’m not affiliated with them. My main goal is to provide a useful service for anyone looking to save money, especially during these tough times when every little bit helps.</p>

    <p>As the site grows, I may explore more affiliation opportunities, but for now, this is purely a project from the heart. If you’ve found this site helpful, I’m glad! My hope is that it helps ease the strain on the purse for us all. Oh and if you could let your friends know about me, I would be eternally grateful</p>

    <p>Thank you for visiting, and I hope you find the best deals on the Lego sets you love!</p>

    <p>Stay up to date with the latest in Lego Deals and Bargains by following us on <a href = "https://x.com/brickpricecouk">TwitterX</a> or <a href ="https://www.facebook.com/groups/925564609568249">Facebook</a></p>

    <p><a href="/">Back to Home</a></p>
  </div>
);

export default About;
