
import {useNavigate} from 'react-router-dom'
import {FaSearch} from "react-icons/fa"
import './SearchDropdown.css'

export default function SearchDropdown({results, updateInput, updateDropDownVisible, updateRedirect, dropdownRef}) {

    let navigate = useNavigate();

    function handleClick (id,name) {
        const origName = name
        console.log(id,name)
        updateInput(name)
        updateDropDownVisible(false)
        name = name.replace(/[^a-zA-Z\d\s:]/g,' ')
        name = name.replace(/ /g,"-")
        console.log('id:' + id)
        if (id !== "search") {
            navigate('/prices/' + name + '-' + id)
        }
        else {
            const a = origName.split(' ')
            const b = a.slice(2,a.length)
            const searchText = b.join(" ")
            navigate('/search/' + searchText)
        }
       
    }

    return (

<div className="results-list" ref={dropdownRef}>
      {results.map((result) => {

        const isSearchIcon = result.id === 'search';
        const imgSrc = isSearchIcon ? '' : `/assets/images/${result.id}.jpg`;

        return (
          <div 
            className="item" 
            key={result.id} // Use result.id directly if it's unique
            onClick={() => handleClick(result.id, result.name)}
          >
            {!isSearchIcon && imgSrc && <img 
                                            src={imgSrc} 
                                            alt={result.name} 
                                            onError={(e) => { e.target.src = "/assets/images/no-image.jpg";}}
                                            />}
            {isSearchIcon && <FaSearch className="search-icon" />}
            {result.name} {isSearchIcon ? '' : result.id}
          </div>
        );
      })}
    </div>
    )
};