import { useState, useEffect } from 'react';
import ReactSlider from 'react-slider';
import './PriceSlider.css'; // Import CSS for custom styles

const PriceSlider = ({ priceRange, handlePriceChange, minPrice, maxPrice }) => {
  const [values, setValues] = useState(priceRange);

  useEffect(() => {
    setValues(priceRange);
  }, [priceRange]);

  const onChange = (newValues) => {
    setValues(newValues);
    handlePriceChange(newValues);
  };

  return (
    <div className="slider-container">
      <ReactSlider
        className="horizontal-slider"
        thumbClassName="thumb"
        trackClassName="track"
        ariaLabel={['Lower thumb', 'Upper thumb']}
        ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
        step={1}
        min={minPrice}
        max={maxPrice}
        value={values}
        onChange={onChange}
        renderTrack={(props, state) => {
          let trackClassName;
          if (state.index === 0) {
            trackClassName = 'track-before';
          } else if (state.index === 1) {
            trackClassName = 'track-selected';
          } else {
            trackClassName = 'track-after';
          }
          return <div {...props} className={`${props.className} ${trackClassName}`} />;
        }}
      />
      <div className="values">
        <span>£ {values[0]}</span>
        <span>£ {values[1]}</span>
      </div>
    </div>
  );
};

export default PriceSlider;
