import { useState, useEffect } from 'react';
import ReactSlider from 'react-slider';
import './PriceSlider.css'; // Import CSS for custom styles

const AgeGroupSlider = ({ ageRange, handleAgeChange, minAge, maxAge }) => {
  const [values, setValues] = useState(ageRange);

  useEffect(() => {
    setValues(ageRange);
  }, [ageRange]);

  const onChange = (newValues) => {
    setValues(newValues);
    handleAgeChange(newValues);
  };

  return (
    <div className="slider-container">
      <ReactSlider
        className="horizontal-slider"
        thumbClassName="thumb"
        trackClassName="track"
        ariaLabel={['Lower thumb', 'Upper thumb']}
        ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
        step={1}
        min={minAge}
        max={maxAge}
        value={values}
        onChange={onChange}
        renderTrack={(props, state) => {
          let trackClassName;
          if (state.index === 0) {
            trackClassName = 'track-before';
          } else if (state.index === 1) {
            trackClassName = 'track-selected';
          } else {
            trackClassName = 'track-after';
          }
          return <div {...props} className={`${props.className} ${trackClassName}`} />;
        }}
      />
      <div className="values">
        <span>{values[0]} years</span>
        <span>{values[1]} years</span>
      </div>
    </div>
  );
};

export default AgeGroupSlider;
