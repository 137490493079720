import Router from './components/Router';
import './App.css';
import Home from './pages/Home'

function App() {
  return (  
    <Router />
  );
}

export default App;
