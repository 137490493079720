import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Prices from '../pages/Prices';
import Search from '../pages/Search';
import Admin from '../pages/Admin';
import Home from '../pages/Home';
import Login from './Login';
import AboutPage from '../pages/About';
import Contact from '../pages/Contact';
import PrivateRoute from './PrivateRoute';
import CookieConsentBanner from './CookieConsentBanner';
import { loadGoogleAnalytics } from '../utils/loadGoogleAnalytics';

export default function Router() {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [cookieConsent, setCookieConsent] = useState(null);

  const saveToken = (token) => {
    localStorage.setItem('token', token);
    setToken(token);
  };

  useEffect(() => {
    const storedConsent = localStorage.getItem('cookieConsent');
    if (storedConsent !== null) {
      const consent = JSON.parse(storedConsent);
      setCookieConsent(consent);
      if (consent) {
        loadGoogleAnalytics('G-1TJ29M6YLF'); // Replace with your Google Analytics Tracking ID
      }
    }
  }, []);

  const handleConsent = (consent) => {
    setCookieConsent(consent);
    localStorage.setItem('cookieConsent', JSON.stringify(consent));
    if (consent) {
      loadGoogleAnalytics('G-1TJ29M6YLF');
    } else {
      // Optionally, remove the analytics script if consent is withdrawn
      const analyticsScript = document.getElementById('google-analytics');
      if (analyticsScript) {
        analyticsScript.remove();
      }
    }
  };

  const resetConsent = () => {
    setCookieConsent(null);
    localStorage.removeItem('cookieConsent');
  };

  function Layout() {
    return (
      <>
        <Header />
        <Outlet />
        <Footer onChangeConsent={resetConsent} /> {/* Pass resetConsent as onChangeConsent */}
        {cookieConsent === null && (
          <CookieConsentBanner onConsent={handleConsent} />
        )}
      </>
    );
  }

  function BrowserRoutes() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/prices/:id" element={<Prices />} />
            <Route path="/search/:text" element={<Search />} />
            <Route path="/browse/:category" element={<Search />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<Contact />} />
            <Route
              path="/admin"
              element={
                <PrivateRoute token={token}>
                  <Admin token={token} />
                </PrivateRoute>
              }
            />
            <Route path="/login" element={<Login setToken={saveToken} />} />
            <Route index element={<Home />} /> {/* Default route */}
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }

  return <BrowserRoutes />;
}


